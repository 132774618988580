import * as React from "react"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { SEO } from "../components/seo"

const sliderSettings = {
  dots: false,
  infinite: true,
  autoplay: true,
	arrows: true,
}

const VencanjaPage = () => {
  return (
    <Layout
      backArrow={true}
      bodyClasses={" flat-menu-page -white-third-bg meny-right "}
    >
      <Breadcrumbs currentPage="Venčanja" />
      <main className="main-content center-aligned">
        <div className="container">
          <h1 className="title-of-page">Venčanja</h1>

					<section className="company-info-section -bottom-indent">
						<div className="row -bottom-indent">
							<div className="col-12 col-lg-12">
                <div style={{position: 'relative', paddingBottom: '56.25%', height: '0', backgroundColor: '#e7e7e7'}}>
                  <iframe style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}} width="560" height="315" src="https://www.youtube.com/embed/f4hR3C8FFE8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
							</div>
						</div>

						<div className="row">
							<div className="col-12 about__company-content">
								<h2 className="title-section title-accent">Savršenstvo. Od prvog plesa <br />do prvih petlova</h2>

								<div className="text-block -no-margin">
                  <p>Pamtićete svoje venčanje kao najlepši san koji je postao stvarnost – od prvih trenutaka do poslednjeg zvuka muzike.</p>
                  <p>Pre nego što ovaj poseban dan zauvek utisnete u svoja sećanja, dozvolite nam da vam pomognemo da ga pretvorite u čarobnu stvarnost.</p>
                  <p>Srdačno vas dočekujemo na mestu gde ćete se osećati kao rok zvezde. Naša posvećenost detaljima je beskompromisna.</p>
                  <p>Spremni smo da dočekamo i do 190 gostiju i oduševimo ih raznolikom gastronomskom ponudom, prilagođenom vašim željama i potrebama.</p>
                  <p>Naši iskusni wedding planeri su tu kako biste maksimalno uživali sa svojim dragim ljudima i sve što trebate uraditi je prepustiti se.</p>
                  <p>Naša izvanredna kuhinja, jedinstven prostor, širok spektar dekoracija i postavki stolova i nameštaja će dodatno obogatiti vaše venčanje, čineći ga nezaboravnim i hvaljenim, još dugo nakon proslave.</p>
                  <p>Imate mogućnost izbora između posluživanja set menija i više izvedbi savršenog švedskog stola.</p>
                  <p>Naša prelepa bašta je na raspolaganju vašim gostima tokom celog događaja, savršeno mesto za ceremoniju venčanja i prijem gostiju u autentičnom okruženju srca grada.</p>
								</div>
							</div>

							<div className="col-12 col-lg-12">

                <h2 className="title-section title-accent" style={{marginTop: '2rem', textAlign: 'center', width: '100%'}}>21.8.2021.</h2>
                <Slider {...sliderSettings}
                  style={{
                    marginBottom: '3rem'
                  }}
                  >
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-8-21/043A3195-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-8-21/043A3239-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-8-21/043A3248-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-8-21/043A3249-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-8-21/043A3332-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-8-21/043A3342-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-8-21/043A3366-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-8-21/043A3403-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-8-21/043A3411-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-8-21/043A3415-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                </Slider>

                <h2 className="title-section title-accent" style={{marginTop: '2rem', textAlign: 'center', width: '100%'}}>19.9.2021.</h2>
                <Slider {...sliderSettings}
                  style={{
                    marginBottom: '3rem'
                  }}
                  >
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-19/043A4481-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-19/043A4520-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-19/043A4562-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-19/043A4643-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-19/043A4670-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-19/043A4691-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-19/043A4711-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                </Slider>

                <h2 className="title-section title-accent" style={{marginTop: '2rem', textAlign: 'center', width: '100%'}}>25.9.2021.</h2>
                <Slider {...sliderSettings}
                  style={{
                    marginBottom: '3rem'
                  }}
                  >
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-1029-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-1036-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-1058-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-1106-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-1183-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-1207-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-1394-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-1527-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-856-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-875-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-916-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-9-25/weddingday-924-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                </Slider>

                <h2 className="title-section title-accent" style={{marginTop: '2rem', textAlign: 'center', width: '100%'}}>30.10.2021.</h2>
                <Slider {...sliderSettings}
                  style={{
                    marginBottom: '3rem'
                  }}
                  >
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-10-30/TEODORA&IVAN-174-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-10-30/TEODORA&IVAN-182-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-10-30/TEODORA&IVAN-236-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-10-30/TEODORA&IVAN-31-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-10-30/TEODORA&IVAN-537-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-10-30/TEODORA&IVAN-615-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-10-30/TEODORA&IVAN-667-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-10-30/TEODORA&IVAN-689-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/vencanja-slajder/2021-10-30/TEODORA&IVAN-829-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                </Slider>
              </div>
						</div>
					</section>

        </div>
      </main>
    </Layout>
  )
}

export default VencanjaPage

export const Head = () => (
  <SEO />
)
